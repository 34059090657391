import React, { Suspense, lazy } from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';

// for ApolloProvider
import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  Observable,
  split,
} from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { onError } from '@apollo/client/link/error';

// Utils
import history from'../utils/history';
import { getValueFromLocalStorage, clearAllLocalStorage } from '../utils/local-storage';
import { deleteAllCookies } from '../utils/cookies';
import permissions from '../utils/permissionConfig';
import { logout } from '../utils/userSessions';
import Auth from'./Auth';
import Loading from '../components/loading';

const Agent = lazy(()=> import('../pages/Agent'));
const CreateAgent = lazy(()=> import('../pages/Agent/createAgent'));
const AgentDetails = lazy(()=> import('../pages/Agent/agentDetails'));
const NotFound = lazy(()=> import('../pages/NotFound'));
const Plans = lazy(()=> import('../pages/Plan'));
const CreatePlan = lazy(()=> import('../pages/Plan/createPlan'));
const CreateBrlPlan = lazy(()=> import('../pages/Plan/CreateBrlPlan'));
const ViewPlans = lazy(()=> import('../pages/Plan/view'));
const ViewBrlPlans = lazy(()=> import('../pages/Plan/BrlView'));
const Rates = lazy(()=> import('../pages/Rate'));
const ViewRates = lazy(()=> import('../pages/Rate/view'));
// const UserList = lazy(()=> import('../pages/Users/list'));
// const UserDetails = lazy(()=> import('../pages/Users/components/Details/index'));
const AddKYC = lazy(()=> import('../pages/Users/addKYC'));

// import ReviewDataCollectionData from '../pages/Users/dataCollection/review';

const CreateVisit = lazy(()=> import('../pages/Visits/createVisit'));
const ViewVisits = lazy(()=> import('../pages/Visits/view'));
const EditPartner = lazy(()=> import('../pages/Partner/editPartner'));
const CreatePartner = lazy(()=> import('../pages/Partner/createPartner'));
const BranchDetails = lazy(()=> import('../pages/Partner/branchDetails'));
// const LoanListing = lazy(()=> import('../pages/loans/list'));
const LoanDetails = lazy(()=> import('../pages/loans/details'));
const Partners = lazy(()=> import('../pages/Partner'));
const PartnerDetails = lazy(()=> import('../pages/Partner/ParnerDetails'));
const PartnerBranchForm = lazy(()=> import('../pages/Partner/PartnerBranchForm'));
const EditAgent = lazy(()=> import('../pages/Agent/editAgent'));
const GoToPartnerApp = lazy(()=> import('../pages/goToPartnerApp'));
const CallbackRequests = lazy(()=> import('../pages/Partner/callbackRequests'));
const Transactions = lazy(()=> import('../pages/Transactions/list'));
const OroOffer = lazy(()=> import('../pages/oro-offer'));
const SupportLoanListing = lazy(()=> import('../pages/supportLoans/list'));
const SupportLoanDetails = lazy(()=> import('../pages/supportLoans/details'));
const CreateOffer = lazy(()=> import('../pages/oro-offer/create'));
const ViewOffer = lazy(()=> import('../pages/oro-offer/view'));
const ManageGoldOrnamentTypes = lazy(()=> import('../pages/goldOrnamentTypes'));
const ManagePinCodes = lazy(()=> import('../pages/PinCodes'));
const CoBorrowerList = lazy(()=> import('../pages/Co-Borrower/list'));
const CoBorrowerDetails = lazy(()=> import('../pages/Co-Borrower/details'));



// Utils

const Login = lazy(()=> import('../pages/Auth/login'));
const Lenders = lazy(()=> import('../pages/OfferLender/list'));
const ViewOldOffer = lazy(()=> import('../pages/old-oro-offer/view'));
const OldOroOffer = lazy(()=> import('../pages/old-oro-offer'));
const CreateOldOffer = lazy(()=> import('../pages/old-oro-offer/create'));
const EditOldOffer = lazy(()=> import('../pages/old-oro-offer/edit'));
const GoldMarkingTypes = lazy(()=> import('../pages/goldMarkingTypes'));
const ReviewDataCollection = lazy(()=> import('../pages/Users/dataCollection/ReviewDataCollection'));
const CreateBrlOffer = lazy(()=> import('../pages/oro-offer/CreateBrlOffer'));
const EditOfferDetails = lazy(()=> import('../pages/oro-offer/editOroOffer'));



function Routes(props) {
  const wsLink = new WebSocketLink({
    uri: process.env.REACT_APP_GQL_SUBSCRIPTION_ENDPOINT,
    options: {
      reconnect: true,
      connectionParams: {
        headers: {
          Authorization: `Bearer ${getValueFromLocalStorage('token')}`,
        },
      },
    },
  });

  const httpLink = new HttpLink({
    uri: process.env.REACT_APP_GQL_ENDPOINT,
    // headers: header
    headers: {
      Authorization: `Bearer ${getValueFromLocalStorage('token')}`,
    },
  });

  const errorLink = onError(
    ({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        console.error('graphQLErrors', graphQLErrors);
      }
      if (networkError) {
        // logoutUser();
        console.error('networkError', networkError);
        // for (let err of networkError) {
        if (networkError.extensions?.code === 'start-failed') {
          // case "start-failed":
          if (
            networkError.message ===
            'cannot start as connection_init failed with : Invalid response from authorization hook'
          ) {
            return new Observable((observer) => {
              logout().then(() => {
                deleteAllCookies();
                clearAllLocalStorage();
                window.location.assign('/');
              });
            });
          }
        } else {
          console.log('default network error handler');
        }
      }
    },
  );

  const link = split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    httpLink,
  );

  const client = new ApolloClient({
    link: errorLink.concat(link),
    // uri: "https://orocrop-dev.nfndev.com/v1/graphql",
    cache: new InMemoryCache({}),
  });

  return (
    <ApolloProvider client={client}>
      <div style={{ margin: '0 auto' }}>
        <Router history={history}>
        <Suspense fallback={Loading}>
          <Switch>
          <Route
              path="/"
              exact
              render={() => {
                return (
                  <Login {...props} setAuthenticated={props.setAuthenticated} />
                );
              }}
            />
            <Route
              path="/login"
              exact
              render={() => {
                return (
                  <Login {...props} setAuthenticated={props.setAuthenticated} />
                );
              }}
            />
            <Auth
              path="/redirect"
              exact
              component={GoToPartnerApp}
            />
            {/* <Auth
              path="/agents"
              exact
              component={Agent}
              requiredPermissions={permissions.agents.permission}
            /> */}
            <Auth
              path="/agents/create"
              exact
              component={CreateAgent}
              requiredPermissions={permissions.agents.create_new_agent.permission}
            />
            <Auth
              path="/agents/view/:id"
              exact
              component={AgentDetails}
              requiredPermissions={permissions.agents.permission}
            />
            <Auth
              path="/agents/edit/:id"
              exact
              component={EditAgent}
              requiredPermissions={permissions.agents.permission}
            />
            {/* <Auth
              path="/config/plans/create"
              exact
              component={CreatePlan}
              requiredPermissions={permissions.config.plans.create_new_plan.permission}
            />
            <Auth
              path="/config/plans/createBrlPlan"
              exact
              component={CreateBrlPlan}
              requiredPermissions={permissions.config.plans.create_new_plan.permission}
            /> */}
            <Auth
              path="/visits/create"
              exact
              component={CreateVisit}            
              requiredPermissions={permissions.visits.create_new_visit.permission}
            />
            <Auth
              path="/callback-requests"
              exact
              component={CallbackRequests}
             
            />
            <Auth
              path="/transactions"
              exact
              component={Transactions}             
            />
            <Auth
              path="/config/partners"
              exact
              component={Partners}            
              requiredPermissions={permissions.config.partners.permission}
            />
            <Auth
              path="/config/partners/create"
              exact
              component={CreatePartner}            
              requiredPermissions={permissions.config.partners.permission}
            />
            <Auth
              path="/config/partners/edit/:id"
              exact
              component={EditPartner}             
              requiredPermissions={permissions.config.partners.permission}
            />
            <Auth
              path="/config/partners/view/:id"
              exact
              component={PartnerDetails}           
              requiredPermissions={permissions.config.partners.permission}
            />
            <Auth
              path="/config/partners/branch/view/:id"
              exact
              component={BranchDetails}
              requiredPermissions={permissions.config.partners.permission}
            />
            <Auth
              path="/config/partners/:partnerId/branch/create"
              exact
              component={PartnerBranchForm}
              requiredPermissions={permissions.config.partners.table_row.create_new_branch.permission}
            />
            <Auth
              path="/config/partners/:partnerId/branch/edit"
              exact
              component={PartnerBranchForm}             
              requiredPermissions={permissions.config.partners.table_row.table_row.edit_branch_details.permission}
            />
            <Auth
              path="/config/plans"
              exact
              component={Plans}             
              requiredPermissions={permissions.config.plans.permission}
            />
            {/* <Auth
              path="/users"
              exact
              component={UserList}              
              requiredPermissions={permissions.users.permission}
            /> */}
            {/* <Auth
              path="/users/view/:id"
              exact
              component={UserDetails}              
              requiredPermissions={permissions.users.permission}
            /> */}
            <Auth
              path="/users/:id/add-user-kyc"
              exact
              component={AddKYC}             
              requiredPermissions={permissions.users.permission}
            />
            <Auth
              path="/users/:id/data-collection"
              exact
              component={ReviewDataCollection}              
              requiredPermissions={permissions.users.permission}
            />
            <Auth
              path='/co-borrower'
              exact
              component={CoBorrowerList}              
              requiredPermissions={permissions.coborrowers.permission}
              />

              <Auth
              path='/co-borrower/view/:id/:customerId'
              exact
              component={CoBorrowerDetails}              
              requiredPermissions={permissions.coborrowers.permission}
              />
            <Auth
              path="/config/rates"
              exact
              component={Rates}              
              requiredPermissions={permissions.config.rates.permission}
            />
            <Auth
              path="/config/plans/view/:id"
              exact
              component={ViewPlans}             
              requiredPermissions={permissions.config.plans.permission}
            />
            <Auth
              path="/config/plans/brl-view/:id"
              exact
              component={ViewBrlPlans}             
              requiredPermissions={permissions.config.plans.permission}
            />
            <Auth
              path="/config/rates/view/:id"
              exact
              component={ViewRates}              
              requiredPermissions={permissions.config.rates.view_rate_card.permission}
            />
            {/* <Auth
              path="/visits/view/:id"
              exact
              component={ViewVisits}              
              requiredPermissions={permissions.visits.permission}
            /> */}
            {/* <Auth
              path="/insights"
              exact
              component={Insights}              
              requiredPermissions={permissions.insights.permission}
            /> */}
            {/* <Auth
              path="/loans"
              exact
              component={LoanListing}              
              requiredPermissions={permissions.loans.permission}
            /> */}
            {/* <Auth
              path="/loans/view/:id"
              exact
              component={LoanDetails}              
              requiredPermissions={permissions.loans.permission}
            /> */}
            {/* <Auth
              path="/support-loans"
              exact
              component={SupportLoanListing}              
              requiredPermissions={permissions.support_loans.permission}
            /> */}
            <Auth
              path="/support-loans/view/:id"
              exact
              component={SupportLoanDetails}              
              requiredPermissions={permissions.support_loans.permission}
            />
            <Auth
              path="/oro-offer"
              exact
              component={OroOffer}              
              requiredPermissions={permissions.oro_offer.view_oro_offer.permission}
            />

            <Auth
              path="/oro-offer/create/:offerId"
              exact
              component={CreateOffer}              
              requiredPermissions={permissions.oro_offer.generate_new_offer.permission}
            />
            <Auth
              path="/oro-offer/create-brl-offer/:offerId"
              exact
              component={CreateBrlOffer}             
              requiredPermissions={permissions.oro_offer.generate_new_offer.permission}
            />
            <Auth
              path="/oro-offer/create/new"
              exact
              component={CreateOffer}             
              requiredPermissions={permissions.oro_offer.generate_new_offer.permission}
            />
            <Auth
              path="/oro-offer/create-brl-offer/new"
              exact
              component={CreateBrlOffer}              
              requiredPermissions={permissions.oro_offer.generate_new_offer.permission}
            />
            <Auth
              path="/oro-offer/view/:offerId"
              exact
              component={ViewOffer}              
              requiredPermissions={permissions.oro_offer.permission}
            />
            <Auth
              path="/oro-offer/edit/:offerId"
              exact
              component={EditOfferDetails}              
              requiredPermissions={permissions.oro_offer.permission}
            />
            <Auth
              path="/config/old-offer"
              exact
              component={OldOroOffer}          
              requiredPermissions={permissions.config.old_offers.view_offer_list.permission}
            />
            <Auth
              path="/config/old-offer/create"
              exact
              component={CreateOldOffer}
              requiredPermissions={permissions.config.old_offers.generate_new_offer.permission}
            />
            <Auth
              path="/config/old-offer/view/:id"
              exact
              component={ViewOldOffer}           
              requiredPermissions={permissions.config.old_offers.permission}
            />
            <Auth
              path="/config/old-offer/edit/:id"
              exact
              component={EditOldOffer}           
              requiredPermissions={permissions.config.old_offers.permission}
            />
            <Auth
              path="/config/manage-gold-ornament-types"
              exact
              component={ManageGoldOrnamentTypes}             
              requiredPermissions={permissions.config.gold_ornaments.permission}
            />
            {/* <Auth
              path="/config/manage-pincodes"
              exact
              component={ManagePinCodes}              
              requiredPermissions={permissions.config.pin_codes.permission}
            /> */}
            <Auth
              path="/config/lenders"
              exact
              component={Lenders}              
              requiredPermissions={permissions.config.lenders.permission}
            />
            <Auth
              path="/config/gold-marking-types"
              exact
              component={GoldMarkingTypes}             
              requiredPermissions={permissions.config.gold_marking.permission}
            />
            <Route path="*" component={NotFound} />
          </Switch>
          </Suspense>
        </Router>
      </div>
    </ApolloProvider>
  );
}

export default Routes;
